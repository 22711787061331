import styles from "./index.module.scss";
import { Button } from "../../../components/button";
import { ReactSVG } from "react-svg";
import download from "../../../assets/img/download.svg";
import hero from "../../../assets/img/hero.gif";
import { Link } from "react-router-dom";

export const Hero = () => {
  return (
    <div className={styles.hero}>
      <div>
        <div className={styles.headers}>
          <h1>
            Beyond
            <br />
            <b>Revolutionary</b>
          </h1>
          <h3>Bridging Blockchain & IRL Projects</h3>
          <p>
            Securely earn interest on your investment while controlling
            real-&#x2060;world projects and goods.
          </p>
        </div>
        <div className={styles.actions}>
          <Link to="/marketplace">
            <Button>Join Now</Button>
          </Link>
          <Button secondary>
            <ReactSVG src={download} /> Learn More
          </Button>
        </div>
      </div>
      <div className={styles.animation}>
        <img src={hero} alt="KWARXS" />
      </div>
    </div>
  );
};
