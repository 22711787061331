import { WalletType } from "../../api";
import { useAuthContext } from "../../context/AuthContext";
import { Button } from "../button";
import { Modal } from "../Modal";
import styles from "./index.module.scss";
import eternlIcon from "../../assets/icons/wallets/eternl.svg";
import laceIcon from "../../assets/icons/wallets/lace.svg";
import namiIcon from "../../assets/icons/wallets/nami.svg";
import vesprIcon from "../../assets/icons/wallets/vespr.svg";
import tokeoIcon from "../../assets/icons/wallets/tokeo.png";

type WalletConnectorModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * A modal that allows the user to select between wallets to connect to.
 * Currently only Nami and Eternl are supported.
 */
export const WalletConnectorModal = ({ isOpen, onClose }: WalletConnectorModalProps) => {
  const { connectWallet, installedWallets, isLoading } = useAuthContext();

  const connectAndClose = (wallet: WalletType) => async () =>{
    await connectWallet(wallet);
    onClose();
  };

  const renderWalletButton = (wallet: WalletType, icon: string, isInstalled: boolean, isRounded?: boolean) => (
    <Button
      onClick={connectAndClose(wallet)}
      disabled={isLoading || !isInstalled}
    >
      <img src={icon} alt={wallet} style={{ borderRadius: isRounded ? "15px" : "0" }} />
      <span>Connect with {wallet}</span>
    </Button>
  );

  return (
    <Modal showModal={isOpen} onClose={onClose}>
      <div className={styles.modalContent}>
        <h2>Connect Wallet</h2>
        {renderWalletButton("Nami", namiIcon, installedWallets.nami)}
        {renderWalletButton("Eternl", eternlIcon, installedWallets.eternl)}
        {renderWalletButton("VESPR", vesprIcon, installedWallets.vespr)}
        {renderWalletButton("Lace", laceIcon, installedWallets.lace)}
        {renderWalletButton("Tokeo", tokeoIcon, installedWallets.tokeo, true)}
      </div>
    </Modal>
  );
};

export default WalletConnectorModal;
