import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames";

type Props = {
  icon?: boolean;
  className?: string;
  secondary?: boolean;
  tertiary?: boolean;
  disabled?: boolean;
  onClick?: (ev: React.MouseEvent) => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({ children, icon, className, secondary, tertiary, onClick, disabled, ...rest }: React.PropsWithChildren<Props>) => {
  return (
    <button
      className={classNames([
        styles.button,
        { [styles.icon]: icon },
        { [styles.secondary]: secondary },
        { [styles.tertiary]: tertiary },
        { [styles.disabled]: disabled },
        className ])}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};
