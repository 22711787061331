import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSdkContext } from "../context/SdkContext";
import { Collection, GYValue } from "../api/generated/models";
import { mkPaddedCounter, tokenNameLength, utf8ToHex } from "../api/utils";
import { mkPoolTokenName } from "../utils/nft";
import { Kwarxs, RewardPoolWithUTxO } from "../api";

export type WithdrawRewardsParams = {
  currencySymbol: string;
  tokenName: string;
  rewardsPool: RewardPoolWithUTxO;
};

export const rewardPoolsQueryKeys = {
  rewardPoolNfts: "rewardPoolNfts",
  rewardPools: "rewardPool",
  rewardPoolsByCollection: "rewardPoolsByCollection",
};

const queryStaleTime = 1000 * 30; // 30 seconds

type UseClaimableRewardsQueryProps = {
  currencySymbol: string;
  tokenName: string;
  prefixLength: number;
}

export const useFindSuitableRewardsPoolQuery = ({ currencySymbol, tokenName, prefixLength }: UseClaimableRewardsQueryProps) => {
  const { getSdk } = useSdkContext();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [ "claimableRewards", currencySymbol, tokenName ],
    queryFn: async () => {
      const sdk = await getSdk();

      // Fetch the reward pool records from the backend
      const rewardNfts = await queryClient.fetchQuery({
        queryKey: [rewardPoolsQueryKeys.rewardPoolNfts],
        queryFn: () => getSdk().then((sdk) => sdk.getRewardNfts()),
        staleTime: queryStaleTime,
      });

      const nftCounter = extractCounterFromTokenName(tokenName, prefixLength);

      const matchingPoolNfts = rewardNfts.filter((rewardNft) => {
        const poolCounter = extractCounterFromTokenName(rewardNft.rewardPoolNftCounter, 0);
        return rewardNft.rewardPoolNftCollection === currencySymbol && poolCounter === nftCounter;
      });

      if (matchingPoolNfts.length === 0) {
        return null;
      }

      const promises = matchingPoolNfts.map((poolNft) => {
        // Fetch the actual matching reward pools from onchain
        return queryClient.fetchQuery({
          queryKey: [ rewardPoolsQueryKeys.rewardPools, poolNft.rewardPoolNftSymbol, poolNft.rewardPoolNftTokenName ],
          queryFn: () => {
            return sdk.getRewardPoolsByAsset({
              policyId: poolNft.rewardPoolNftSymbol,
              tokenName: poolNft.rewardPoolNftTokenName,
            });
          },
          staleTime: queryStaleTime,
        });
      });

      const results = await Promise.all(promises);

      const poolsWithSufficientValue = results.flat().filter((pool) => {
        const claimableRewards = Kwarxs.extractClaimableAmount(pool);
        const poolValue = pool.poolUTxO[1];

        const rewardAssets = Object.keys(claimableRewards);

        return rewardAssets.every(asset => {
          if (!(asset in poolValue)) return false;

          if (typeof claimableRewards[asset] !== "number" || typeof poolValue[asset] !== "number") {
            throw new Error(`Reward pool has invalid data for asset ${asset}. A number is required.`);
          }

          return poolValue[asset] >= claimableRewards[asset];
        });
      });

      // Select random reward pool to avoid congestion
      const pool = pickRandomFrom(poolsWithSufficientValue);

      return { pool, claimableAmount: Kwarxs.extractClaimableAmount(pool) };
    },
  });
};

type UseRewardPoolsQueryProps = {
  collectionCurrencySymbol: string;
}

export const useRewardPoolsQuery = ({ collectionCurrencySymbol }: UseRewardPoolsQueryProps) =>{
  const { getSdk } = useSdkContext();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [ rewardPoolsQueryKeys.rewardPoolsByCollection, collectionCurrencySymbol ],
    staleTime: queryStaleTime,
    queryFn: async () => {
      const sdk = await getSdk();

      // Fetch latest reward pool NFTs
      const rewardPoolNfts = await queryClient.fetchQuery({
        queryKey: [rewardPoolsQueryKeys.rewardPoolNfts],
        queryFn: () => getSdk().then((sdk) => sdk.getRewardNfts()),
        staleTime: queryStaleTime,
      });

      // Find reward pool NFTs that match the collection currency symbol
      const matchingPoolNfts = rewardPoolNfts.filter((nft) => nft.rewardPoolNftCollection === collectionCurrencySymbol);

      // Fetch reward pools for each matching reward pool NFT.
      const promises = matchingPoolNfts.map((poolNft) => {
        return sdk.getRewardPoolsByAsset({
          policyId: poolNft.rewardPoolNftSymbol,
          tokenName: poolNft.rewardPoolNftTokenName,
        });
      });
      const results = await Promise.all(promises);

      return results.flat();
    },
  });
};

export const useRewardPoolNftsQuery = () => {
  const { getSdk } = useSdkContext();
  return useQuery({
    queryKey: [rewardPoolsQueryKeys.rewardPoolNfts],
    queryFn: () => getSdk().then((sdk) => sdk.getRewardNfts()),
  });
};

type AddRewardPoolMutationProps = {
  collection: Collection;
  rewardValue: GYValue;
  dividendsPerPool: number;
  poolCount: number;
}

export const useAddRewardPoolMutation = () => {
  const { getSdk } = useSdkContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      collection,
      rewardValue,
      dividendsPerPool,
      poolCount,
    }: AddRewardPoolMutationProps) => {
      const sdk = await getSdk();

      const { tokenNameHex } = mkPoolTokenName(collection);

      const rewardsPool = {
        counter:  mkPaddedCounter(tokenNameLength - collection.prefixLength, 1),
        dividendsPerPool,
        nftCs: collection.currencySymbol,
        poolCount,
        poolTn: tokenNameHex,
        rewardValue,
      };

      return sdk.createRewardPools(rewardsPool);
    },
    onSuccess: (_, { collection }) => {
      queryClient.invalidateQueries({ queryKey: [ rewardPoolsQueryKeys.rewardPoolsByCollection, collection.currencySymbol ]});
    },
  });
};

export const useWithdrawRewardsMutation = () => {
  const { getSdk } = useSdkContext();
  return useMutation({
    mutationFn: async ({ currencySymbol, tokenName, rewardsPool }: WithdrawRewardsParams) => {
      const sdk = await getSdk();

      return sdk.withdrawReward({
        pool: rewardsPool,
        nftTokenNames: [utf8ToHex(tokenName)],
        collectionPolicyId: currencySymbol,
      });
    },
  });
};

function pickRandomFrom<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}


/**
 * Extracts the counter from a token name as an integer.
 * E.g., "some-collection-0000000000000012" -> 12
 * @param tokenName The token name to extract the counter from
 * @returns The counter as an integer
 * @throws If the token name does not have the expected format
 */
function extractCounterFromTokenName(tokenName: string, prefixLength: number): number {
  return parseInt(tokenName.slice(prefixLength), 10);
}
